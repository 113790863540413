import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/default.js";
import { Section, SectionHeader } from 'src/components/molecules/Section';
import { Image } from 'src/containers/Image2';
import { SEO } from 'src/containers/SEO';
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  {
    saraSaraCommunitySvg: file(relativePath: { eq: "sara/sara-community.svg" }) {
      ...Image2NoSharp
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Event Code of Conduct" description="Our mission is to empower all makers to create AI assistants that work for everyone, and we're committed to creating an inclusive, respectful and friendly environment. This Code of Conduct should not only help you understand how to act within the community, but also help you to understand what to expect from others in this space." mdxType="SEO" />
    <Section maxWidth="small" mdxType="Section">
      <p>{`At Rasa, our mission is to empower all makers to create AI assistants that work for everyone. To achieve this, we believe it’s imperative that we grow our community of makers and professionals from all around the world with different backgrounds and levels of experience in conversational AI. To empower you, our community with the means to excel in your projects through the sharing of knowledge and support you in finding solutions to any obstacles in your path.`}</p>
      <p>{`Our event and conferences are an opportunity for us to achieve this mission, however, we can only achieve these goals if participants and attendees create a safe space for everyone. This Code of Conduct (the "Code") describe your obligations when you attend our events, as a speaker, guest, volunteer, attendees, or otherwise (a "Participant"). This Code applies to everyone who attend our events, including our online events.`}</p>
      <p>{`If you have any questions on this Code, you can reach us by email at `}<a parentName="p" {...{
          "href": "mailto:legal@rasa.com"
        }}>{`legal@rasa.com`}</a></p>
      <p>{`This Code should not only help you understand how to act within the community, but also help you to understand what to expect from others in this space.`}</p>
      <ol>
        <li parentName="ol">{`Expected Behavior`}</li>
      </ol>
      <ul>
        <li parentName="ul">{`Respect and Be Excellent to Each Other`}</li>
      </ul>
      <p>{`The base of every great community is mutual respect and trust. Having strong opinions on topics is completely understandable and acceptable. In fact, we encourage people to be passionate about the topics presented and discussed in our events. We welcome sharp, factual feedback and valuable discussions, but not abusive criticism, attacks on people in a derogatory form or discussions with the sole purpose to start arguments. Be kind to each other.`}</p>
      <ul>
        <li parentName="ul">{`Approach Matters Constructively`}</li>
      </ul>
      <p>{`Disagreements, both social and technical, happen all the time. It is important that we resolve disagreements and differing views constructively. Let’s focus on working together to resolve issues, learning from mistakes and finding solutions.`}</p>
      <ul>
        <li parentName="ul">{`Take Responsibility for Your Words and Actions`}</li>
      </ul>
      <p>{`We all make mistakes. If someone has been harmed or offended, we listen carefully and respectfully, and work to right the wrong.`}</p>
      <ol {...{
        "start": 2
      }}>
        <li parentName="ol">{`Unacceptable Behavior`}</li>
      </ol>
      <ul>
        <li parentName="ul">{`Violence, threats of violence, or violent language directed against another person`}</li>
        <li parentName="ul">{`Possession of a weapon or any item that can be used as a weapon, which may be used to threaten or cause injury to others if used in a certain manner.`}</li>
        <li parentName="ul">{`Harassment`}</li>
      </ul>
      <p>{`We do not tolerate harassment of event participants in any form. Sexual language and imagery are not appropriate for any event, including talks, workshops, parties, X (Twitter), and other online platforms. Event participants violating these rules may be sanctioned or expelled from the event without a refund, at the discretion of the event organizers. In the case of online and in-person events, harassment includes, but is not limited to:`}</p>
      <ul>
        <li parentName="ul">{`Verbal or written comments that reinforce social structures of domination (related to gender, gender identity and expression, sexual orientation, disability, physical appearance, body size, race, age, religion).`}</li>
        <li parentName="ul">{`Threats of violence, to harm others or hate speech`}</li>
        <li parentName="ul">{`Impersonation, exposing someone's private information`}</li>
        <li parentName="ul">{`Sustained disruption of talks or other events`}</li>
        <li parentName="ul">{`Sexual images in public spaces or posted in an event chat`}</li>
        <li parentName="ul">{`Deliberate intimidation or stalking`}</li>
        <li parentName="ul">{`Harassing photography or recording`}</li>
        <li parentName="ul">{`Inappropriate physical contact`}</li>
        <li parentName="ul">{`Unwelcome sexual attention or contact`}</li>
        <li parentName="ul">{`Advocating for, or encouraging, any of the above behaviour`}</li>
      </ul>
      <ol {...{
        "start": 3
      }}>
        <li parentName="ol">
          <p parentName="li">{`Drugs and Alcohol Rasa encourages responsible alcohol consumption at all our events. At no time should Participants behave in a manner which is inappropriate. If any Participant is found to be overly intoxicated and posing a safety risk, Rasa reserves the right to remove Participant from the event. Any illicit substances are strictly prohibited at our events and conferences.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Health & Safety We are committed to provide a safe and health environment for all participants. You agree to comply with applicable local health and safety legal requirements. If you’re feeling unwell, please rest up and stay at home so we can continue to keep our fellow event attendees and community safe. In the even`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Language In our commitment to a harassment-free and inclusive environment, we strongly believe it’s important to pay attention to harmful language patterns. This includes not using ableist or sexist language.`}</p>
        </li>
      </ol>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`Ableism Words like "crazy," "dumb," "insane" or "lame" are examples of ableist language, devaluating people who have physical or mental disabilities. Its appearance often stems not from any intentional desire to offend, but from our innate sense of what it means to be normal. These words can be avoided by using more fitting, clearer descriptions of what we want to communicate.To find out more about ableism and replacement terms please read this guide.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Sexism Using gendered terms like "dude" or "guys" to address a mixed-gendered group of people contributes to furthering the exclusion of underrepresented individuals. We strongly advise avoiding gendered pronouns as well as gendered terms.`}</p>
        </li>
      </ul>
      <p>{`For more information, please familiarize yourself with the Geek Feminism wiki guide.`}</p>
      <ol {...{
        "start": 6
      }}>
        <li parentName="ol">{`Enforcement Participants asked to stop any harassing behaviour are expected to comply immediately.`}</li>
      </ol>
      <p>{`If a community member engages in harassing behaviour, community management and/or event organizers retain the right to take any actions to maintain a welcoming environment for all. This includes, but is not limited to, warning the offender or expulsion from the event with no refund, and/or banning them from future events.`}</p>
      <p>{`Event organizers may take action to address anything designed to, or with the clear impact of, disrupting the event or making the environment hostile for any participants. We expect participants to follow these rules at all event venues, online events and event-related social activities.`}</p>
      <ol {...{
        "start": 7
      }}>
        <li parentName="ol">{`Reporting If someone makes you or anyone else feel unsafe or unwelcome, please report it as soon as possible.`}</li>
      </ol>
      <p>{`Here’s how you can make a report:`}</p>
      <ul>
        <li parentName="ul">{`Email us at `}<a parentName="li" {...{
            "href": "mailto:community@rasa.com"
          }}>{`community@rasa.com`}</a></li>
        <li parentName="ul">{`During an in-person event, you can also contact a team member, identified by organizer t-shirts.`}</li>
        <li parentName="ul">{`When taking your report, our staff will handle it with full discretion. We will fully investigate it and take whatever action is necessary to prevent a recurrence, which may involve other event staff to ensure your report is managed properly. Once safe, we’ll ask you to tell us about what happened. This can be upsetting, but we’ll handle it as respectfully as possible, and you can bring someone to support you. You won’t be asked to confront anyone, and we won’t tell anyone who you are without your prior consent.`}</li>
      </ul>
      <p>{`For in-person events, we can also help you contact the hotel/venue security or provide escorts.`}</p>
      <p>{`"Language" Attribution: This text is based on Conference Code of Conduct, The Geek Feminism wiki and the work of Valerie Aurora published under CC-BY 3.0 license.`}</p>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      